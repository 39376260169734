// File: components/Header.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import MenuIcon from '@mui/icons-material/Menu';

function Header() {
    const { user, signOut } = useAuth();

    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuItems = [
        { label: 'Home', path: '/' },
        { label: 'Help', path: '/help' },
        { label: 'Matches', path: '/matches' },
        ...(user ? [
            { label: 'My Robots', path: '/my-robots' },
            { label: 'Logout', onClick: signOut }
        ] : [
            { label: 'Login', path: '/login' }
        ])
    ];

    return (
        <AppBar position="static" color="primary">
            <Toolbar>
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    AI Battle Arena
                </Typography>
                {isMobile ? (
                    <>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleMenu}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {menuItems.map((item) => (
                                <MenuItem
                                    key={item.label}
                                    onClick={() => {
                                        handleClose();
                                        if (item.onClick) {
                                            item.onClick();
                                        }
                                    }}
                                    component={item.path ? RouterLink : 'li'}
                                    to={item.path}
                                >
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                ) : (
                    menuItems.map((item) => (
                        <Button
                            key={item.label}
                            color="inherit"
                            component={item.path ? RouterLink : 'button'}
                            to={item.path}
                            onClick={item.onClick}
                        >
                            {item.label}
                        </Button>
                    ))
                )}
            </Toolbar>
        </AppBar>
    );
}

export default Header;