// File: pages/CompletedMatchDetails.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box, CircularProgress, List, Accordion, AccordionSummary, AccordionDetails, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { useSupabase } from '../contexts/SupabaseContext';
import { useGames } from '../contexts/GamesContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

function CompletedMatchDetails() {
    const { id } = useParams();
    const { supabase } = useSupabase();
    const { games } = useGames();

    const [match, setMatch] = useState(null);
    const [robotNames, setRobotNames] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    dayjs.extend(utc);

    useEffect(() => {
        fetchMatchDetails();
    }, [id]);

    const fetchMatchDetails = async () => {
        const { data, error } = await supabase
            .from('match')
            .select('*')
            .eq('id', id)
            .single();

        if (error) {
            console.error('Error fetching match details:', error);
        } else {
            setMatch(data);
            await fetchRobotNames(data.robot_instances);
        }
        setIsLoading(false);
    };

    const fetchRobotNames = async (robotInstanceIds) => {
        const { data, error } = await supabase
            .from('robot_instance')
            .select('id, robot (name)')
            .in('id', robotInstanceIds);

        if (error) {
            console.error('Error fetching robot names:', error);
        } else {
            setRobotNames(Object.fromEntries(data.map(instance => [instance.id, instance.robot.name])));
        }
    };

    const plays = {
        P: "Paper",
        S: "Scissors",
        R: "Rock",
        " ": "Invalid"
    };

    const processHistory = (hist) => {
        let pHist = [];
        for (let i = 0; i < hist.choices1.length; i++) {
            pHist.push({
                choice1: plays[hist.choices1[i]],
                choice2: plays[hist.choices2[i]],
                points1: i ? pHist[i - 1].points1 + hist.points1[i] : hist.points1[i],
                points2: i ? pHist[i - 1].points2 + hist.points2[i] : hist.points2[i]
            });
        }
        return pHist;
    };

    const renderTicTacToeBoard = (board) => {
        let boardData;

        if (typeof board === 'string') {
            try {
                boardData = JSON.parse(board);
            } catch {
                boardData = board.split('\n').map(row => row.split(''));
            }
        } else if (Array.isArray(board)) {
            boardData = board;
        } else {
            return <Typography>Unable to render board</Typography>;
        }

        if (!Array.isArray(boardData) || boardData.length === 0) {
            return <Typography>Invalid board data</Typography>;
        }

        return (
            <TableContainer component={Paper} style={{ maxWidth: 300, margin: 'auto' }}>
                <Table size="small">
                    <TableBody>
                        {boardData.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <TableCell key={cellIndex} align="center" style={{ padding: '8px', border: '1px solid #ddd' }}>
                                        {cell || '-'}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    if (isLoading) {
        return (
            <Container maxWidth="md" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Completed Match Details
                </Typography>
                {match && (
                    <Box mb={4}>
                        <Typography variant="h6">Game: {games.find(g => g.id === match.game)?.name}</Typography>
                        <Typography>Date: {dayjs.utc(match.start).local().format('YYYY-MM-DD HH:mm')}</Typography>
                        <Typography>Participants: {match.robot_instances.map(id => robotNames[id] || id).join(', ')}</Typography>
                        <Typography>Winner: {match.winner ? robotNames[match.winner] || match.winner : 'Draw'}</Typography>
                    </Box>
                )}

                <Typography variant="h5" gutterBottom>Match History</Typography>
                {match && match.game == 1 && match.history && (
                    <List>
                        {JSON.parse(match.history).map((move, index) => (
                            <Accordion key={index}>
                                <AccordionSummary
                                    expandIcon={<Typography>▼</Typography>}
                                    aria-controls={`panel${index}-content`}
                                    id={`panel${index}-header`}
                                >
                                    <Typography>Move {index + 1} - {robotNames[move.player_id] || move.player_id} ({move.player_color})</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Tool: {move.tool}</Typography>
                                    <Typography>Parameters: {JSON.stringify(move.params)}</Typography>
                                    <Typography>Message: {move.message}</Typography>
                                    <Box mt={2}>
                                        <Typography variant="subtitle1">Board:</Typography>
                                        {renderTicTacToeBoard(move.board)}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </List>
                )}

                {match && match.game == 2 && match.history && (
                    <TableContainer component={Paper} style={{ maxWidth: '70%', margin: 'auto' }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="center">{robotNames[match.robot_instances[0]]}</TableCell>
                                    <TableCell align="center">{robotNames[match.robot_instances[1]]}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {processHistory(JSON.parse(match.history)).map((turn, index) => (
                                    <TableRow key={index + 1}>
                                        <TableCell key={0} align="center" style={{ padding: '8px', border: '1px solid #ddd' }}>
                                            {"Turn " + (index + 1)}
                                        </TableCell>

                                        <TableCell key={1} align="center" style={{ padding: '8px', border: '1px solid #ddd' }}>
                                            {turn.choice1 + " (" + turn.points1 + ")"}
                                        </TableCell>

                                        <TableCell key={2} align="center" style={{ padding: '8px', border: '1px solid #ddd' }}>
                                            {turn.choice2 + " (" + turn.points2 + ")"}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                )}
            </Box>
        </Container>
    );
}

export default CompletedMatchDetails;