// File: pages/RobotDetails.js
import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Select, MenuItem, Button, Box, CircularProgress, Snackbar, Alert } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useSupabase } from '../contexts/SupabaseContext';
import { useGames } from '../contexts/GamesContext';

function RobotDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { supabase } = useSupabase();

    const [robot, setRobot] = useState(null);
    const [models, setModels] = useState([]);
    const [editedModel, setEditedModel] = useState('');
    const [editedScript, setEditedScript] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const { games, isLoading: isLoadingGames } = useGames();

    useEffect(() => {
        if (!isLoadingGames) {
            fetchRobotDetails();
        }
        fetchModels();
    }, [id, isLoadingGames]);

    const fetchRobotDetails = async () => {
        setIsLoading(true);
        try {
            // Fetch robot details
            const { data: robotData, error: robotError } = await supabase
                .from('robot')
                .select('*')
                .eq('id', id)
                .single();

            if (robotError) throw robotError;

            // Fetch the most recent robot instance
            const { data: instanceData, error: instanceError } = await supabase
                .from('robot_instance')
                .select('*')
                .eq('robot', id)
                .order('created_at', { ascending: false })
                .limit(1)
                .single();

            if (instanceError) throw instanceError;

            robotData.gameObj = games.find(g => g.id === robotData.game);
            // id and created_at get overwritten ... but doesn't really matter.
            setRobot({ ...robotData, ...instanceData });

            setEditedModel(instanceData.model);
            setEditedScript(instanceData.script);
        } catch (error) {
            console.error('Error fetching robot details:', error);
            setErrorMessage('Failed to fetch robot details. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchModels = async () => {
        const { data, error } = await supabase
            .from('model')
            .select('id, name');

        if (error) {
            console.error('Error fetching models:', error);
            setErrorMessage('Failed to fetch models. Please try again.');
        } else {
            setModels(data);
        }
    };

    const handleUpdate = async () => {
        setIsUpdating(true);
        try {
            const { data, error } = await supabase
                .from('robot_instance')
                .insert([
                    {
                        robot: robot.robot,
                        model: editedModel,
                        script: editedScript,
                        version: robot.version + 1
                    }
                ]);

            if (error) throw error;

            setSuccessMessage('Robot updated successfully!');
            setTimeout(() => {
                navigate('/my-robots');
            }, 2000); // Navigate after 2 seconds

        } catch (error) {
            console.error('Error updating robot:', error);
            setErrorMessage('Failed to update robot. Please try again.');
        } finally {
            setIsUpdating(false);
        }
    };

    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorMessage('');
        setSuccessMessage('');
    };

    if (isLoading) {
        return (
            <Container maxWidth="md" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Container>
        );
    }

    if (!robot) {
        return (
            <Container maxWidth="md">
                <Typography variant="h4" component="h1" gutterBottom>
                    Robot not found
                </Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Robot Details
                </Typography>
                <Box mb={2}>
                    <TextField
                        fullWidth
                        label="Name"
                        value={robot.name}
                        InputProps={{ readOnly: true }}
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        fullWidth
                        label="Game"
                        value={robot.gameObj?.name}
                        InputProps={{ readOnly: true }}
                    />
                </Box>
                <Box mb={2}>
                    <Select
                        fullWidth
                        value={editedModel}
                        onChange={(e) => {
                            setEditedModel(e.target.value);
                            setIsEdited(true);
                        }}
                        label="Model"
                    >
                        {models.map((m) => (
                            <MenuItem key={m.id} value={m.id}>{m.name}</MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box mb={2}>
                    <TextField
                        fullWidth
                        multiline
                        rows={10}
                        label="Script"
                        value={editedScript}
                        onChange={(e) => {
                            setEditedScript(e.target.value);
                            setIsEdited(true);
                        }}
                    />
                </Box>
                <Box mb={2}>
                    <Typography variant="body2">
                        Version: {robot.version}
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpdate}
                    disabled={!isEdited || isUpdating}
                >
                    {isUpdating ? 'Updating...' : 'Update'}
                </Button>
                <Snackbar open={!!successMessage} autoHideDuration={2000} onClose={handleCloseMessage}>
                    <Alert onClose={handleCloseMessage} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </Container>
    );
}

export default RobotDetails;