// File: pages/CreateRobot.js
import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Select, MenuItem, Button, Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSupabase } from '../contexts/SupabaseContext';
import { useAuth } from '../contexts/AuthContext';
import { useGames } from '../contexts/GamesContext'; // Not controlling if the games are loaded. Do we really need it?

function CreateRobot() {
    const [game, setGame] = useState('');
    const [name, setName] = useState('');
    const [model, setModel] = useState('');
    const [script, setScript] = useState('');
    const [models, setModels] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { supabase } = useSupabase();
    const { user } = useAuth();
    const navigate = useNavigate();
    const { games } = useGames();

    useEffect(() => {
        fetchModels();
    }, []);

    const fetchModels = async () => {
        const { data: modelsData, error: modelsError } = await supabase
            .from('model')
            .select('id, name');

        if (modelsError) {
            console.error('Error fetching models:', modelsError);
        } else {
            setModels(modelsData);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            // Insert into robot table
            const { data: robotData, error: robotError } = await supabase
                .from('robot')
                .insert([
                    { game, owner: user.id, name }
                ])
                .select();

            if (robotError) throw robotError;

            // Insert into robot_instance table
            const { error: instanceError } = await supabase
                .from('robot_instance')
                .insert([
                    { robot: robotData[0].id, model, script, version: 1 }
                ]);

            if (instanceError) throw instanceError;

            navigate('/my-robots');
        } catch (error) {
            console.error('Error creating robot:', error);
            // Here you might want to show an error message to the user
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <Container maxWidth="md" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Create New Robot
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Box mb={2}>
                        <Select
                            fullWidth
                            value={game}
                            onChange={(e) => setGame(e.target.value)}
                            displayEmpty
                            required
                            label="Game"
                        >
                            <MenuItem value="" disabled>Select a game</MenuItem>
                            {games.map((g) => (
                                <MenuItem key={g.id} value={g.id}>{g.name}</MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            label="Robot Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <Select
                            fullWidth
                            value={model}
                            onChange={(e) => setModel(e.target.value)}
                            displayEmpty
                            required
                            label="Model"
                        >
                            <MenuItem value="" disabled>Select a model</MenuItem>
                            {models.map((m) => (
                                <MenuItem key={m.id} value={m.id}>{m.name}</MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            multiline
                            rows={10}
                            label="Script"
                            value={script}
                            onChange={(e) => setScript(e.target.value)}
                            required
                        />
                    </Box>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Create Robot
                    </Button>
                </form>
            </Box>
        </Container>
    );
}

export default CreateRobot;