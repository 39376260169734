// File: pages/OpenMatchDetails.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Select, MenuItem, Button, Box, CircularProgress, List, ListItem, ListItemText } from '@mui/material';
import { useSupabase } from '../contexts/SupabaseContext';
import { useAuth } from '../contexts/AuthContext';
import { useGames } from '../contexts/GamesContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

function OpenMatchDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { supabase } = useSupabase();
    const { user } = useAuth();
    const { games } = useGames();

    const [match, setMatch] = useState(null);
    const [eligibleRobots, setEligibleRobots] = useState([]);
    const [selectedRobot, setSelectedRobot] = useState('');
    const [contestants, setContestants] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isApplying, setIsApplying] = useState(false);

    useEffect(() => {
        fetchMatchDetails();
        fetchContestants();
    }, [id]);

    useEffect(() => {
        if (match) {
            fetchEligibleRobots();
        }
    }, [match]);

    const fetchMatchDetails = async () => {
        const { data, error } = await supabase
            .from('match')
            .select('*')
            .eq('id', id)
            .single();

        if (error) {
            console.error('Error fetching match details:', error);
        } else {
            setMatch(data);
        }
    };

    const fetchEligibleRobots = async () => {
        if (!match) return;


        const { data, error } = await supabase
            .from('robot')
            .select(`
        id,
        name
      `)
            .eq('owner', user.id)
            .eq('game', match.game)
            .order('created_at', { ascending: false });

        if (error) {
            console.error('Error fetching eligible robots:', error);
        } else {
            setEligibleRobots(data);
        }
        setIsLoading(false);
    };

    /*     const fetchContestants = async () => {
            const { data, error } = await supabase
                .from('match_join_application')
                .select(`
            id,
            robot_instance (
              id,
              robot (
                name
              )
            )
          `)
                .eq('match', id);
    
            if (error) {
                console.error('Error fetching contestants:', error);
            } else {
                setContestants(data);
            }
        }; */

    const fetchContestants = async () => {
        const { data, error } = await supabase.rpc('retrieve_match_contestants', { p_match: id });

        if (error) {
            console.error('Error fetching contestants:', error);
        } else {
            console.log("Joromania: contestants=" + JSON.stringify(data));
            setContestants(data);
        }
    };

    const handleApply = async () => {
        console.log("Joromania: en handleApply, selectedRobot=" + selectedRobot);
        if (!selectedRobot) return;

        setIsApplying(true);
        //const robotInstance = eligibleRobots.find(robot => robot.id === selectedRobot).instanceId;

        const { error } = await supabase
            .from('match_join_application')
            .insert([
                { match: id, robot: selectedRobot }
            ]);

        if (error) {
            console.error('Error applying to match:', error);
        } else {
            fetchContestants();
        }
        setIsApplying(false);
    };

    if (isLoading) {
        return (
            <Container maxWidth="md" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Match Details
                </Typography>
                {match && (
                    <Box mb={4}>
                        <Typography variant="h6">Game: {games.find(g => g.id === match.game)?.name}</Typography>
                        <Typography>Status: {match.status}</Typography>
                    </Box>
                )}

                {(contestants.length < 2) && (
                    <>
                        <Typography variant="h5" gutterBottom>Register for Match</Typography>
                        <Box display="flex" alignItems="center" mb={4}>
                            <Select
                                value={selectedRobot}
                                onChange={(e) => setSelectedRobot(e.target.value)}
                                displayEmpty
                                style={{ minWidth: 200, marginRight: 16 }}
                            >
                                <MenuItem value="" disabled>Select a robot</MenuItem>
                                {eligibleRobots.map((robot) => (
                                    <MenuItem key={robot.id} value={robot.id}>{robot.name}</MenuItem>
                                ))}
                            </Select>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleApply}
                                disabled={!selectedRobot || isApplying}
                            >
                                {isApplying ? 'Applying...' : 'Apply'}
                            </Button>
                        </Box>
                    </>)
                }

                <Typography variant="h5" gutterBottom>Current Contestants</Typography>
                <List>
                    {contestants && contestants.length > 0 ? (
                        contestants.map((contestant, index) => (
                            <ListItem key={contestant.id}>
                                <ListItemText
                                    primary={`${index + 1}. ${contestant.robot_name}`}
                                    secondary={
                                        <>
                                            Owner: {contestant.user_name}
                                            <br />
                                            Applied: {dayjs.utc(contestant.created_at).local().format('YYYY-MM-DD HH:mm:ss')}
                                        </>
                                    }
                                />
                            </ListItem>
                        ))
                    ) : (
                        <ListItem>
                            <ListItemText primary="Nothing here yet. Apply now!" />
                        </ListItem>
                    )}
                </List>
            </Box>
        </Container>
    );
}

export default OpenMatchDetails;