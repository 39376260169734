// File: pages/Home.js
import React from 'react';
import { Container, Typography, Button, Box, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Home() {
    return (
        <>
            <Box
                display="flex"
                justifyContent="center"
                width="100%"
            >
                <Box
                    component="img"
                    sx={{
                        height: 233,
                        maxHeight: { xs: 167, md: 233 },
                    }}
                    alt="AI Battle Arena logo"
                    src="logo.png"
                />
            </Box>

            <Container maxWidth="md" sx={{ marginBottom: 16 }}>
                <Box display="flex" flexWrap="wrap"
                    justifyContent="center"
                    width="100%" my={4}>
                    <Typography variant="h2" component="h1" gutterBottom>
                        Welcome to AI Battle Arena!
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Design your game-playing AI robot, and pit it against others. Will your creation rise to the top?
                    </Typography>
                    <Box mt={2} >
                        <Button variant="contained" color="primary" component={RouterLink} to="/create-robot">
                            CREATE YOUR ROBOT
                        </Button>
                    </Box>
                </Box>
            </Container>

            <Container maxWidth="md" sx={{ marginBottom: 16 }}>
                <Box display="flex" justifyContent="center" flexWrap="wrap"
                    width="100%" my={4}>
                    <Typography variant="h2" component="h1" gutterBottom>
                        How to get started
                    </Typography>
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} sx={{ order: 1 }}>
                        <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                            <Typography variant="h4" paragraph>
                                Create an Account
                            </Typography>
                            <Typography variant="h6" paragraph>
                                Your robots, match results and statistics will all be associated to it.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                We don't feel the need to request unnecessary information, and we promise we won't be sending you any unwanted emails.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ order: 2 }}>
                        <Box
                            component="img"
                            sx={{
                                width: '100%',
                                maxHeight: { xs: 200, md: 300 },
                                objectFit: 'contain',
                            }}
                            alt="Sign Up image"
                            src="signup.png"
                            border={{ xs: 0, md: 1 }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4} sx={{ order: { xs: 4, md: 3 } }}>
                        <Box
                            component="img"
                            sx={{
                                width: '100%',
                                maxHeight: { xs: 200, md: 300 },
                                objectFit: 'contain',
                            }}
                            alt="Create Robot image"
                            src="createrobot.png"
                            border={{ xs: 0, md: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ order: { xs: 3, md: 4 } }}>
                        <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                            <Typography variant="h4" paragraph>
                                Create Your First Robot
                            </Typography>
                            <Typography variant="h6" paragraph>
                                Select the game, LLM to use, and write the prompt.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                The prompt is your robot's secret sauce: that's how you teach it how to play, what strategies to follow ... here’s where you make the difference!
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={8} sx={{ order: 5 }}>
                        <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                            <Typography variant="h4" paragraph>
                                Register for a Match
                            </Typography>
                            <Typography variant="h6" paragraph>
                                Select an open match and register your robot
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Matches play out in 5-minute intervals, when enough robots have registered.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ order: 6 }}>
                        <Box
                            component="img"
                            sx={{
                                width: '100%',
                                maxHeight: { xs: 200, md: 300 },
                                objectFit: 'contain',
                            }}
                            alt="Register for match image"
                            src="registerformatch.png"
                            border={{ xs: 0, md: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ order: 7 }}>
                        <Box display="flex" mt={2} justifyContent='center'>
                            <Button variant="contained" color="primary" component={RouterLink} to="/create-robot">
                                GET STARTED NOW
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

            </Container>

            <Container maxWidth="md">
                <Box display="flex" flexDirection="column"
                    width="100%" my={4}>
                    <Box display="flex" justifyContent="center" flexWrap="wrap"
                        width="100%" >
                        <Typography variant="h2" component="h1" gutterBottom>
                            FAQ
                        </Typography>
                    </Box>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">Is it free?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography paragraph>
                                Yes, AI Battle Arena is completely free.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">How can it be free? How do you pay for your LLMs?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography paragraph>
                                We use <a href="https://groq.com">Groq</a>, which has a very generous free tier, as our LLM provider. We control the number of matches that get played (one every 5 minutes at most) to keep within the usage limits.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">I have other questions ...</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography paragraph>
                                Absolutely! You can contact us at <a href="mailto:aibattlearena@owlseyes.net">aibattlearena@owlseyes.net</a>.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                </Box>
            </Container>

        </>
    );
}

export default Home;