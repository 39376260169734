// File: pages/MyRobots.js
import React, { useState, useEffect } from 'react';
import { Container, Typography, List, ListItem, ListItemText, Button, Box, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useSupabase } from '../contexts/SupabaseContext';
import { useAuth } from '../contexts/AuthContext';
import { useGames } from '../contexts/GamesContext';

function MyRobots() {
    const [robots, setRobots] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { supabase } = useSupabase();
    const { user } = useAuth();
    const navigate = useNavigate();
    const { games, isLoading: isLoadingGames } = useGames();

    useEffect(() => {
        if (user && !isLoadingGames) {
            fetchRobots();
        }
    }, [user, isLoadingGames]);

    const fetchRobots = async () => {
        setIsLoading(true);
        const { data, error } = await supabase
            .from('robot')
            .select('id, created_at, game, name')
            .eq('owner', user.id)
            .order('created_at', { ascending: false });

        if (error) {
            console.error('Error fetching robots:', error);
        } else {
            data.forEach(r => {
                r.gameObj = games.find(g => g.id === r.game);
            });
            setRobots(data);
        }
        setIsLoading(false);
    };

    const handleCreateRobot = () => {
        navigate('/create-robot');
    };

    if (isLoading) {
        return (
            <Container maxWidth="md" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h4" component="h1" gutterBottom>
                        My Robots
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleCreateRobot}>
                        Create New Robot
                    </Button>
                </Box>
                <List>
                    {robots.map((robot) => (
                        <ListItem
                            key={robot.id}
                            button
                            component={Link}
                            to={`/robot/${robot.id}`}
                            divider
                        >
                            <ListItemText
                                primary={robot.name}
                                secondary={`Game: ${robot.gameObj?.name}, Created: ${new Date(robot.created_at).toLocaleDateString()}`}
                            />
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Container >
    );
}

export default MyRobots;