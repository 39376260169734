// File: App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/Header';
import Home from './pages/Home';
import CreateRobot from './pages/CreateRobot';
import MyRobots from './pages/MyRobots';
import Matches from './pages/Matches';
import Login from './pages/Login';
import { SupabaseProvider } from './contexts/SupabaseContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { GamesProvider } from './contexts/GamesContext';
import RobotDetails from './pages/RobotDetails';
import OpenMatchDetails from './pages/OpenMatchDetails';
import CompletedMatchDetails from './pages/CompletedMatchDetails';
import Help from './pages/Help';

const theme = createTheme({
  palette: {
    primary: {
      light: '#331133',
      main: '#331133',
      dark: '#331133',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

function PrivateRoute({ children }) {
  const { user } = useAuth();
  return user ? children : <Navigate to="/login" />;
}



function App() {
  return (
    <SupabaseProvider>
      <AuthProvider>
        <GamesProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
              <Header />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/help" element={<Help />} />
                <Route path="/create-robot" element={<PrivateRoute><CreateRobot /></PrivateRoute>} />
                <Route path="/my-robots" element={<PrivateRoute><MyRobots /></PrivateRoute>} />
                <Route path="/matches" element={<Matches />} />
                <Route path="/robot/:id" element={<PrivateRoute><RobotDetails /></PrivateRoute>} />
                <Route path="/match/:id" element={<PrivateRoute><OpenMatchDetails /></PrivateRoute>} />
                <Route path="/completed-match/:id" element={<CompletedMatchDetails />} />
              </Routes>
            </Router>
          </ThemeProvider>
        </GamesProvider>
      </AuthProvider>
    </SupabaseProvider>
  );
}

export default App;