// File: pages/Matches.js
import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Tabs,
    Tab,
    Box,
    List,
    ListItem,
    ListItemText,
    Button,
    CircularProgress,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useSupabase } from '../contexts/SupabaseContext';
import { useAuth } from '../contexts/AuthContext';
import { useGames } from '../contexts/GamesContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

function Matches() {
    const [openMatches, setOpenMatches] = useState([]);
    const [completedMatches, setCompletedMatches] = useState([]);
    const [robotNames, setRobotNames] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [userRobotFilter, setUserRobotFilter] = useState(false);
    const [gameFilter, setGameFilter] = useState('');
    const { supabase } = useSupabase();
    const { user } = useAuth();
    const { games, isLoading: isLoadingGames } = useGames();
    const [tabValue, setTabValue] = useState(user ? "open" : "completed");

    useEffect(() => {
        fetchMatches();
        const interval = setInterval(fetchMatches, 60000); // Refresh every minute
        return () => clearInterval(interval);
    }, [userRobotFilter, gameFilter]);

    const fetchMatches = async () => {
        setIsLoading(true);
        await fetchOpenMatches();
        await fetchCompletedMatches();
        setIsLoading(false);
    };

    const fetchOpenMatches = async () => {
        const { data: openData, error: openError } = await supabase
            .from('match')
            .select(`
                id,
                game,
                robot_instances,
                start,
                status
            `)
            .eq('status', 'open')
            .order('start', { ascending: true });

        if (openError) console.error('Error fetching open matches:', openError);
        else setOpenMatches(openData);
    };

    const fetchCompletedMatches = async () => {
        let query = supabase
            .from('match')
            .select(`
                id,
                game,
                robot_instances,
                winner,
                start,
                status
            `)
            .eq('status', 'completed')
            .order('start', { ascending: false });

        if (gameFilter) {
            query = query.eq('game', gameFilter);
        }

        if (userRobotFilter) {
            const { data: userRobots } = await supabase
                .from('robot')
                .select('id')
                .eq('owner', user.id);

            const userRobotIds = userRobots.map(robot => robot.id);
            query = query.contains('robot_instances', userRobotIds);
        }

        const { data: completedData, error: completedError } = await query;

        if (completedError) console.error('Error fetching completed matches:', completedError);
        else {
            setCompletedMatches(completedData);
            await fetchRobotNames(completedData);
        }
    };

    const fetchRobotNames = async (matches) => {
        const robotIds = new Set();
        matches.forEach(match => {
            match.robot_instances.forEach(id => robotIds.add(id));
            if (match.winner) robotIds.add(match.winner);
        });

        const { data, error } = await supabase
            .from('robot_instance')
            .select('id, robot (name)')
            .in('id', Array.from(robotIds));

        if (error) {
            console.error('Error fetching robot names:', error);
            return;
        }

        setRobotNames(Object.fromEntries(data.map(instance => [instance.id, instance.robot.name])));
    };

    const renderOpenMatches = () => (
        <List>
            {openMatches.map(match => (
                <ListItem key={match.id}>
                    <ListItemText
                        primary={`Game: ${games.find(g => g.id === match.game)?.name}`}
                    />
                    <Button component={Link} to={`/match/${match.id}`} variant="outlined">
                        Details
                    </Button>
                </ListItem>
            ))}
        </List>
    );

    const renderCompletedMatches = () => (
        <>
            {/*
            <Box mb={2} >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={userRobotFilter}
                            onChange={(e) => setUserRobotFilter(e.target.checked)}
                            name="userRobotFilter"
                        />
                    }
                    label="Show only matches with my robots"
                />
            </Box>
            */}
            <Box mb={2}>
                <Select
                    value={gameFilter}
                    onChange={(e) => setGameFilter(e.target.value)}
                    displayEmpty
                    fullWidth
                >
                    <MenuItem value="">All Games</MenuItem>
                    {games.map((game) => (
                        <MenuItem key={game.id} value={game.id}>{game.name}</MenuItem>
                    ))}
                </Select>
            </Box>
            <List>
                {completedMatches.map(match => (
                    <ListItem key={match.id}>
                        <ListItemText
                            primary={`Game: ${games.find(g => g.id === match.game)?.name}`}
                            secondary={
                                <>
                                    Date: {dayjs.utc(match.start).local().format('YYYY-MM-DD HH:mm')}
                                    <br />
                                    Participants: {match.robot_instances.map(id => robotNames[id] || id).join(', ')}
                                    <br />
                                    Winner: {robotNames[match.winner] || 'Draw'}
                                </>
                            }
                        />
                        <Button component={Link} to={`/completed-match/${match.id}`} variant="outlined">
                            Details
                        </Button>
                    </ListItem>
                ))}
            </List>
        </>
    );

    if (isLoading || isLoadingGames) {
        return (
            <Container maxWidth="md" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Matches
                </Typography>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                    <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
                        {(user) ? <Tab value="open" label="Open Matches" /> : null}
                        <Tab value="completed" label="Completed Matches" />
                    </Tabs>
                </Box>
                {tabValue === 'open' ? renderOpenMatches() : renderCompletedMatches()}
            </Box>
        </Container>
    );
}

export default Matches;