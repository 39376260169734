// File: context/GamesContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSupabase } from './SupabaseContext';

const GamesContext = createContext();

export function GamesProvider({ children }) {
    const [games, setGames] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { supabase } = useSupabase();

    useEffect(() => {
        fetchGames();
    }, []);

    const fetchGames = async () => {
        try {
            const { data, error } = await supabase
                .from('game')
                .select('id, name');

            if (error) throw error;

            setGames(data);
        } catch (error) {
            console.error('Error fetching games:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <GamesContext.Provider value={{ games, isLoading }}>
            {children}
        </GamesContext.Provider>
    );
}

export function useGames() {
    return useContext(GamesContext);
}