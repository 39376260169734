// File: contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSupabase } from './SupabaseContext';

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const { supabase } = useSupabase();
    const [user, setUser] = useState(null);

    useEffect(() => {
        // Check active session
        const session = supabase.auth.getSession();
        setUser(session?.user ?? null);

        // Listen for changes on auth state
        const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
            setUser(session?.user ?? null);
        });

        return () => {
            authListener.subscription.unsubscribe();
        };
    }, [supabase.auth]);

    const signIn = (email, password) => supabase.auth.signInWithPassword({ email, password });
    const signUp = async (name, email, password) => supabase.auth.signUp({ email, password, options: { data: { name: name } } });
    const signOut = () => supabase.auth.signOut();

    const value = {
        user,
        signIn,
        signUp,
        signOut,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return useContext(AuthContext);
}